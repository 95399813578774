.kpi-card {
    text-align: center;
    border: unset;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

    background-color: #ffffff12 !important;
    border-radius: 25px !important;
    margin-right: 20px;
    padding: 10px;
}

.kpi-card .card-title {
    color: #fffffff2;
    font-family: Figtree;
    font-size: 18px;
    font-style: normal;
}

.kpi-card .card-text {

    color: #fffffff2;
    font-family: Figtree;
    font-style: normal;
    line-height: 125.61%;
}

.view-log-btn {
    color: #aeaeae;
    cursor: pointer;
    text-decoration: underline;
    font-size: 13px;
}

.json-display {
    color: rgba(255, 255, 255, 0.90);
    font-family: Figtree;
    font-size: 16px;
}

.json-d-h {
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.61%;
    opacity: 0.7;
}

.log-modal-content{
    scrollbar-color: #001DFF #000210;
    overflow-y: auto;
    max-height : 400px;
}