.back-btn {
    border-radius: 25px;
    background: rgba(255, 255, 255, 0.05);
    width: fit-content;
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 10px 20px;
    cursor: pointer;
}

.t-d-ticker-img {
    width: 35px;
    height: 35px;
}

.t-d-ticker-name {
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 125.61%;
    /* 27.634px */
    opacity: 0.9;
}

.t-d-ticker-symbol {
    color: rgba(255, 255, 255, 0.95);
    leading-trim: both;
    text-edge: cap;
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 125.61%;
    /* 17.585px */
    opacity: 0.7;
}

.t-d-ticker-price {
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 125.61%;
    /* 40.195px */
    opacity: 0.9;
}

.statistics-header {
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 125.61%;
    /* 20.098px */
    text-transform: capitalize;
    opacity: 0.9;
}

.statistics-right-arrow {
    color: #001DFF;
    cursor: pointer;
}

.hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #41434d;
    margin: 1em 0;
    padding: 0;
    /* opacity: 0.2; */
}

.t-t-m-card {
    min-height: 272px;
}

.total-men-table {
    width: 100%;

}

.today-summary {
    min-height: 337px;
}

.total-men-table td {
    border: 0.5px solid rgba(255, 255, 255, 0.20);
    padding: 10px 20px;
    color: rgba(255, 255, 255, 0.95);
    text-align: center;
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 125.61%;
    background: #000210;
    /* 20.098px */
}

.total-men-table th {
    border: 0.5px solid rgba(255, 255, 255, 0.20);
    padding: 10px 20px;
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.61%;
    opacity: 0.9;
    /* 15.073px */
    text-transform: capitalize;
}

.summary-num {
    text-align: center;
    font-family: Figtree;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 125.61%;
    /* 30.146px */
    text-transform: capitalize;
}

.summary-label {
    color: rgba(255, 255, 255, 0.95);
    text-align: center;
    font-family: Figtree;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.61%;
    /* 15.073px */
    text-transform: capitalize;
}

.bullish {
    color: #31CA5B;
}

.bearish {
    color: #FF484B;
}

.neutral {
    color: rgba(255, 255, 255, 0.95);
}

.summary-text li {
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.statistics-date {
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 125.61%;
    /* 15.073px */
    opacity: 0.7;
}

.total-result {
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 125.61%;
    /* 16.329px */
    opacity: 0.7;
}

.t-d-crypto-web-img {
    /* width: 30px; */
}

.a-t-w {
    border-radius: 25px;
    border: 0.5px solid rgba(255, 255, 255, 0.50);
    color: rgba(255, 255, 255, 0.95);
    leading-trim: both;
    text-edge: cap;
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.61%;
    opacity: 0.7;
    padding: 13px 20px;
    display: flex;
    align-items: center;
}

.t-d-ctw-btn {
    border-radius: 25px;
    border: 1px solid #001DFF;
    background: #001DFF;
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.61%;
    padding: 10px 15px;
    cursor: pointer;
    width: fit-content;
    /* 20.098px */
}

.read-more-btn {
    color: #aeaeae;
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-decoration-line: underline;
    cursor: pointer;
}

.t-d-user-img {
    border-radius: 25px;
    width: 40px;
    height: 40px;
}

.summary-user {
    opacity: 0.7;
}

.search-on-twitter {
    border-radius: 25px;
    border: 0.5px solid rgba(255, 255, 255, 0.50);
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding: 10px 20px;
    color: rgba(255, 255, 255, 0.95);
    opacity: 0.7;
    cursor: pointer;
}

.tweet-modal .modal-content {
    background-color: #000210 !important;
    border-radius: 30px !important;
}

.tweet-modal .modal-footer {
    border-top: unset !important
}

.tweet-modal .modal-header {
    border-bottom: unset !important;
}

.view-tweet-btn {
    border-radius: 25px;
    border: 0.5px solid rgba(255, 255, 255, 0.50);
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding: 10px 0px;
    text-align: center;
    color: rgba(255, 255, 255, 0.95);
    opacity: 0.7;
    cursor: pointer;
}

.go-to-tweet-btn {
    background-color: #001DFF !important;
    border: 1px solid #001DFF !important;
    padding: 8px 20px !important;
    border-radius: 25px !important;
}

.modal-close-icon {
    color: #fff;
    float: right;
    cursor: pointer;
}

.tweet-complete-text {
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    /* This will add "..." to text that overflows its container */
    max-width: 100%;
    /* Ensure this div doesn't exceed the <td> width */
}

/* styles.css */

.percentage-increase {
    color: green;
    background-color: #d4edda;
    padding: 5px 8px;
    border-radius: 25px;
    font-size: 14px;
    font-family: Figtree;
}

.percentage-decrease {
    color: red;
    background-color: #f8d7da;
    /* Light red */
    padding: 5px 8px;
    border-radius: 25px;
    font-size: 14px;
    font-family: Figtree;
}


.token-detail-complete-text {
    max-width: 350px;
    /* Adjust based on your layout needs */
    max-height: 100px;
    /* Adjust based on your layout needs */
    overflow-y: auto;
    /* Enable vertical scrolling */
    white-space: normal;
    /* Allow the text to wrap within the max-width */
    display: block;
    /* Ensure the div behaves correctly regarding width and height */
    scrollbar-width: thin;
    /* "auto" or "thin" */
    scrollbar-color: #001DFF #000210;
    /* thumb and track color */
}

/* Customizes the scrollbar's track */
.token-detail-complete-text::-webkit-scrollbar-track {
    background: #f0f0f0;
    /* Light gray track background */
}

/* Customizes the scrollbar itself */
.token-detail-complete-text::-webkit-scrollbar {
    width: 8px;
    /* Thickness of the scrollbar */
}

/* Customizes the thumb (the draggable part of the scrollbar) */
.token-detail-complete-text::-webkit-scrollbar-thumb {
    background: #888;
    /* Darker shade for the thumb */
    border-radius: 4px;
    /* Optional: adds rounded corners to the thumb */
}

/* Changes the thumb color on hover for better visibility */
.token-detail-complete-text::-webkit-scrollbar-thumb:hover {
    background: #555;
}


.summary-tweet-link {
    cursor: pointer;
    text-decoration: underline;
    color: #aeaeae;
    margin-left: 5px;
    font-family: Figtree;
    font-size: 16px;
}

.token-detail-timestamp {
    font-size: 13px;
    color: hsla(240, 7%, 62%, 1);
    margin-top: 10px;
}


.token-table {
    border-collapse: collapse;
    width: 100%;
}

.header-row {}

.header-cell {
    padding: 8px;
    text-align: center;
    border: 0.5px solid rgba(255, 255, 255, 0.20);
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.61%;
    opacity: 0.9;
    text-transform: capitalize;

}

.data-cell {
    border: 0.5px solid rgba(255, 255, 255, 0.20);
    padding: 10px 0px;
    color: rgba(255, 255, 255, 0.95);
    text-align: center;
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 125.61%;
    background: #000210;
}

.price-header {

    border-left: 1px solid black;
}

.t-d-notes-btn {
    border-radius: 25px;
    border: 0.5px solid rgba(255, 255, 255, 0.50);
    color: rgba(255, 255, 255, 0.95);
    leading-trim: both;
    text-edge: cap;
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.61%;
    opacity: 0.7;
    padding: 13px 20px;
    display: flex;
    align-items: center;
}

.summary-tweet-modal {
    display: grid;
    place-items: center;
    height: 500px;
    overflow-x: auto;
    scrollbar-width: 1px;
    scrollbar-color: #001DFF #000210;
}

.influencer-tag {
    background: rgba(0, 29, 255, 0.20);
    width: fit-content;
    padding: 1px 10px;
    border-radius: 25px;
    margin-top: 5px;
    font-size: 13px;
    font-family: Figtree;
}


.notes-tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.notes-tab {
    margin: 0 10px;
    padding: 10px 20px;
    cursor: pointer;
    color: #888;
    border-bottom: 2px solid transparent;
}

.notes-tab.active {
    color: white;
    border-bottom: 2px solid #001DFF;
}

.notes-count{
    background: #FF9800;
    border-radius: 25px;
    font-size: 12px;
    font-family: 'Figtree';
    padding: 1px 6px;
    font-weight: 600;
    color: #fff;
}

/* .note-content{
    display: flex;
    justify-content: space-between;
} */

.note-content-section {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    color: rgba(255, 255, 255, 0.95);
    height: 400px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #001DFF #000210;
    /* background: #121421; */
    /* padding: 15px; */
}

.notes-container {
    flex-grow: 1;
    overflow-y: auto;
    min-height: 300px;
}

.submitted-note-container {
    background-color: #000210;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #212529;
}

.note-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.note-username {
    color: rgba(255, 255, 255, 0.95);
}

.note-time {
    color: #888;
    font-size: 12px;
}

.note-input-container {
    display: flex;
    align-items: center;
    /* background-color: #1a1a1a;
    padding: 10px 0; */
}

.note-input {
    flex-grow: 1;
    background-color: #000210;
    border: 1px solid #444;
    border-radius: 20px;
    color: white;
    padding: 10px;
    resize: none;
    margin-right: 10px;
    outline: none;
}

.note-submit-btn {
    background-color: #001DFF;
    border: none;
    color: white;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-heading {
    font-family: Figtree;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.61%;
    color: rgba(255, 255, 255, 0.95);
}

.note-delete-btn {
    cursor: pointer;
}

.statistics-token-description {
    color: #fff;
    font-size: 16px;
    font-family: 'Figtree';
}

.no-profile-data {
    color: rgba(255, 255, 255, 0.90) !important;
}

.placeholder-text {
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 15px;
}

.note-edit-input {
    background: #000210;
    border: 1px solid #001dff;
    border-radius: 10px;
    color: #fff;
    padding: 10px;
    width: 100%;
}

.note-save-btn {
    border-radius: 25px;
    border: 1px solid #001DFF;
    background: #001DFF;
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.61%;
    padding: 5px 10px;
    cursor: pointer;
}

.note-cancel-btn{
    border-radius: 25px;
    border: 0.5px solid rgba(255, 255, 255, 0.50);
    background: unset;
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.61%;
    opacity: 0.7;
    padding: 5px 10px;
    align-items: center;
}

.sort-icon{
    font-size: 20px;
    color: #0d6efd;
}

.min-height-600{
    min-height: 650px;
}

.token-categories{
    color: #fff;
    font-size: 16px;
    font-family: 'Figtree';
    opacity: 0.7;
}