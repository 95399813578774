.react-tweet-theme{
    /* margin-right: auto !important;
    margin-left: auto !important; */
}

.feed-filter{
    position: fixed;
    width: inherit;
}

.latest-feed-loader{
    position: fixed;
    left: 0;
    left: 60%;
    transform: translate(-60%, 0);
    z-index: 1;
    color: #fff;
    background-color: #001DFF;
    border-radius: 25px;
    padding: 5px 15px;
    cursor: pointer;
}


@media (max-width: 768px) {
    .feed-filter{
        position: relative;
    }
    .latest-feed-loader{
        top: 10px;
    }
}

.influencer-tweet-feed {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px; /* Add some spacing between feeds */
  }
  

  .centered-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}