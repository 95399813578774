.custom-dropdown-g-tw {
    position: relative;
    width: 250px;
    border-radius: 25px;
    border: 0.5px solid rgba(255, 255, 255, 0.25);
    background: #000210;
}


.generate-tweet-col-left {
    border-radius: 25px 0px 0px 25px;
    background: rgba(255, 255, 255, 0.05);
    padding: 20px;
    width: 50%;
    /* margin-left: 20px; */
}

.generate-tweet-col-right {
    border-radius: 0px 25px 25px 0px;
    /* border: 0.5px solid rgba(255, 255, 255, 0.25); */
    background: #010318;
    width: 50%;
    /* margin-right: 20px; */
}

.timeframe-label {
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 125.61%;
    opacity: 0.7;
}

.time-window-g-tw {
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.61%;
    padding: 10px;
    opacity: 0.7;
    cursor: pointer;
    margin-right: 20px;
    margin-left: 20px;
}

.time-window-g-tw.active {
    border-radius: 25px;
    background: rgba(0, 29, 255, 0.35);
    opacity: 1;
}


.tw-sentiments-form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.tw-sentiments-form label {
    display: flex;
    cursor: pointer;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    margin-bottom: 0.375em;
    align-items: center;
    /* Accessible outline */
    /* Remove comment to use */
    /*
        &:focus-within {
                outline: .125em solid $primary-color;
        }
    */
}

.tw-sentiments-form label input {
    position: absolute;
    left: -9999px;
}

.tw-sentiments-form label input:checked+.sentiment-category:before {
    box-shadow: inset 0 0 0 0.4375em #0d0f1c;
    background: #001DFF;
}

.tw-sentiments-form label .sentiment-category {
    display: flex;
    align-items: center;
    padding: 0.375em 0.75em 0.375em 0.375em;
    border-radius: 99em;
    transition: 0.25s ease;
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.61%;
    /* 20.098px */
}


.tw-sentiments-form label .sentiment-category:before {
    display: flex;
    flex-shrink: 0;
    content: "";
    /* background-color: #fff; */
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    margin-right: 0.375em;
    transition: 0.25s ease;
    /* box-shadow: inset 0 0 0 0.125em #00005c; */
    border: 1px solid #343a40;
}

.tw-sentiments-form-container {
    padding: 20px;
}

.generate-tweet-tabs {
    border-bottom: 1px solid #252732 !important;
}

.generate-tweet-tabs .nav-link {
    color: rgba(255, 255, 255, 0.95) !important;
    opacity: 0.6 !important;
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 125.61%;
    background-color: #0d0f1c !important;
    border-left: unset !important;
    border-right: unset !important;
    border-top: unset !important;
    border-color: #252732 !important;
    /* 20.098px */
}

.generate-tweet-tabs .nav-link.active {
    color: rgba(255, 255, 255, 0.95) !important;
    border-color: #001DFF !important;
    opacity: 0.9 !important;
    border: 3px solid;
}

.generate-tweet-placeholder {
    color: rgba(255, 255, 255, 0.95);
    opacity: 0.4;
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    /* 157.143% */

}

.gen-tw-btn {
    border-radius: 25px;
    border: 1px solid #001DFF;
    background: #001DFF;
    text-align: center;
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 125.61%;
    padding: 10px 20px;
    cursor: pointer;
    /* 20.098px */
}

.gen-tw-close-button {
    border-radius: 25px !important;
    padding: 8px 20px !important;
}

.generated-tweet-text {
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.61%;

    /* 20.098px */
}

.generated-tweet-text-container {
    padding: 20px;
}

.generated-tweet-text-box {
    border-radius: 25px;
    background: rgba(255, 255, 255, 0.07);
    padding: 20px;
}

.share-tweet-btn {
    border-radius: 25px;
    border: 0.5px solid rgba(255, 255, 255, 0.50);
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.61%;
    padding: 10px 10px;
    text-align: center;
    margin-left: 10px;
    /* 17.585px */
}

.sentiment-count {
    color: #C7F2A7;
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 125.61%;
    /* 17.585px */
}

.read-more-less-btn {
    font-family: Figtree;
    background: none;
    color: #007bff;
    border: none;
    padding: 0;
    font-size: 14px;
    cursor: pointer;
}

@media (max-width: 768px) {

    .generate-tweet-col-left,
    .generate-tweet-col-right {
        width: 100%;
    }

    .time-window-g-tw {
        margin-left: unset;
        margin-right: unset;
        font-size: 15px;
        padding: 8px;
    }
}

.generate-tweet-select-container {
    max-height: 600px;
    overflow-y: auto;
}

.gen-tweet-modal {
    width: 35vw !important;
    max-width: 35vw !important;
}


/* Style container for custom checkbox */
.tweet-selection-checkbox {
    position: relative;
    display: inline-block;
    width: 24px;
    /* Custom checkbox width */
    height: 24px;
    /* Custom checkbox height */
    margin-right: 10px;
    /* Space between checkbox and tweet text */
}

/* Hide the default checkbox input */
.tweet-selection-checkbox input[type="checkbox"] {
    opacity: 0;
    /* Makes the checkbox invisible but still interactable */
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

/* Custom checkbox styling */
.tweet-selection-checkbox label:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    /* Same as container width */
    height: 24px;
    /* Same as container height */
    background-color: #f0f0f0;
    /* Light grey background */
    border: 2px solid #dcdcdc;
    /* Grey border */
    cursor: pointer;
}

/* Style for when checkbox is checked */
.tweet-selection-checkbox input[type="checkbox"]:checked+label:before {
    background-color: #007bff;
    /* Change background color when checked */
}

/* Checkmark icon using 'after' pseudo-element */
.tweet-selection-checkbox label:after {
    content: '';
    display: none;
    position: absolute;
    left: 9px;
    /* Adjust these values to center the checkmark */
    top: 5px;
    /* Adjust these values to center the checkmark */
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

/* Display checkmark when checked */
.tweet-selection-checkbox input[type="checkbox"]:checked+label:after {
    display: block;
}

.tweet-selection-item {
    display: flex;
    align-items: start;
    border-bottom: 1px solid;
    margin-top: 14px;
}

.generate-tweet-select-header-text {
    font-family: Figtree;
    font-size: 20px;
    color: #fff;
}

.gen-tweet-modal .modal-header {
    border-bottom: 1px solid #212529;
}

.gen-tweet-error-detail{
    color: #dc3545; 
    font-family: Figtree;
    font-size: 16px;
    margin-left: 10px;
}

.selcted-tweet-count{
    font-size: 15px;
    color: #001DFF;
    padding-left: 10px;
    font-weight: 600;
}

.metric{
    color: #fff;
    font-size: 15px;
}

.metric-close-icon{
    margin-left: 5px;
    font-size: 16px;
    cursor: pointer;
    color: #dc3545;
}