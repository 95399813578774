.remove-watchlist-btn {
    border: 0.5px solid rgba(255, 72, 75, 0.8);
    background-color: unset !important;
    font-family: Figtree;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 72, 75, 0.8);
    border-radius: 25px;
    padding: 8px 15px;
}

.my-watch-list-heading {
    font-family: Figtree;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 0.95);
}

.watchlist-tabs {
    border-bottom: 1px solid #252732 !important;
}

.watchlist-tabs .nav-link {
    color: rgba(255, 255, 255, 0.95) !important;
    opacity: 0.6 !important;
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 125.61%;
    background-color: transparent !important;
    border-left: unset !important;
    border-right: unset !important;
    border-top: unset !important;
    border-color: #252732 !important;
    /* 20.098px */
}

.watchlist-tabs .nav-link.active {
    color: rgba(255, 255, 255, 0.95) !important;
    border-color: #001DFF !important;
    opacity: 0.9 !important;
    border: 3px solid;
}


.search-container {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    position: relative;
    /* width: fit-content; */
}

.search-input {
    border: none;
    outline: none;
    padding-right: 30px;
    padding: 8px 15px !important;
    border-radius: 20px !important;
    width: 100%;
    /* Adjust the width as needed */
    background-color: transparent !important;
    border: 0.5px solid;
    border-color: rgba(255, 255, 255, 0.25) !important;
    color: #fff;
}

.search-icon {
    color: #333;
    margin-left: -30px;
    /* Adjust as needed to position the icon inside the input box */
    cursor: pointer;
}

/* Placeholder styling */
.search-input::placeholder {
    color: #999;
}

/* Icon from FontAwesome */
.fas.fa-search {
    color: #333;
}

.add-new-watchlist {
    display: flex;
    align-items: center;
    font-family: Figtree;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(0, 29, 255, 1);
    padding: 5px 10px;
    cursor: pointer;
}

.add-new-watchlist-modal .modal-content {
    background: linear-gradient(0deg, #000210, #000210), linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3));
    border: 0.5px solid rgba(255, 255, 255, 0.3);
    border-radius: 25px;

}

.watchlist-influencer-list {
    height: 200px;
    overflow: auto;
    border: 0.5px solid;
    border-radius: 25px;
    margin-top: 15px;
}

.influencer-list-item {
    font-family: Figtree;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 0.9);
    padding: 8px 10px;
    cursor: pointer;
    border-bottom: 0.5px solid #40414c;
}



.search-icon,
.clear-search-button {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background: none;
    cursor: pointer;
}

/* Style for the clear button */
.clear-search-button {
    font-size: 1em;
    color: #333;
    /* Adjust size as needed */
}

/* Optional: Hide the browser's clear button on input fields */
.search-input[type="search"]::-webkit-search-cancel-button,
.search-input[type="search"]::-webkit-search-decoration,
.search-input[type="search"]::-webkit-clear-button {
    -webkit-appearance: none;
    appearance: none;
    margin: -1px;
    /* Negate margin to effectively hide the button */
}

.no-results {
    font-family: Figtree;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 0.9);
    padding: 10px;
}

.suggest-watchlist {
    background-color: transparent !important;
    border: 0.5px solid;
    border-color: rgba(255, 255, 255, 0.25) !important;
    color: #fff;
    padding: 8px 15px !important;
    border-radius: 20px !important;
}

.suggest-watchlist-btn {
    background: rgba(0, 29, 255, 1);
    border: 1px solid rgba(0, 29, 255, 1);
    font-family: Figtree;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 0.95);
    border-radius: 25px;
    width: 100%;
    padding: 8px 10px;
    text-align: center;
}

.influencer-watchtlist-close{
    float: right;
    color: #eee;
    cursor: pointer;
}