/* Dropdown Container */
.filter-dropdown-container {
    position: relative;
    width: 300px;
    margin-top: 10px;
}

/* Search Input */
.filter-search-input {
    width: 100%;
    padding: 8px;
    margin-bottom: 5px;
    box-sizing: border-box;
    border-radius: 25px !important;
    border-color: rgba(255, 255, 255, 0.25);
    border: 0.5px solid rgba(255, 255, 255, 0.25);
    background-color: #000210 !important;
    font-family: Figtree;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
}

/* Dropdown Menu with Transition */
.filter-dropdown-menu {
    position: absolute;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    z-index: 100;
    border-radius: 25px !important;
    border-color: rgba(255, 255, 255, 0.25) !important;
    border: 0.5px solid rgba(255, 255, 255, 0.25);
    padding: 15px;
    background-color: #000210 !important;
    scrollbar-color: #001DFF #000210;
}
.filter-dropdown-menu-1{
    position: absolute;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    z-index: 100;
    border-radius: 25px !important;
    border-color: rgba(255, 255, 255, 0.25) !important;
    border: 0.5px solid rgba(255, 255, 255, 0.25);
    padding: 15px;
    background-color: #000210 !important;
    scrollbar-color: #001DFF #000210;   
}


/* Adjust max-height for transition effect when dropdown is visible */
.filter-dropdown-container:focus-within .filter-dropdown-menu {
    max-height: 200px;
    /* Adjust based on your needs */
}

/* Checkbox Container */
.checkbox-container {
    padding: 5px 10px;
}

.checkbox-container label {
    color: rgba(255, 255, 255, 0.95) !important;
    font-family: Figtree;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
}


.token-label{
    color: rgba(255, 255, 255, 0.95) !important;
    font-family: Figtree;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

/* Token Checkbox */
.token-checkbox {
    margin-right: 5px;
}

/* Add additional styles for hover effects, focus states, etc., as needed */

.selected-tokens {
    font-size: 14px;
    font-family: Figtree;
    background: #000213;
    width: fit-content;
    margin: auto;
    /* opacity: 0.7; */
    padding: 3px 10px;
    border-radius: 30px;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.25);
    border: 0.5px solid rgba(255, 255, 255, 0.25);
    max-height: 10em;
    /* white-space: nowrap; */
    /* Prevents text from wrapping to ensure 'lines' are based on width */
    overflow-x: hidden;
    overflow-y: auto;
}

.reset-filter-button {
    border-radius: 25px;
    background: rgba(0, 29, 255, 0.35);
    opacity: 1;
    border: unset;
    font-family: 'Figtree';
    font-size: 15px;
    color: rgba(255, 255, 255, 0.95);
    padding: 5px 10px;
    margin: auto;
    text-align: center;
}


/* Category Dropdown Styles */
.category-name {
    font-size: 16px;
    font-family: Figtree;
    color: #fff;
}

.category-section-heading {
    font-size: 16px;
    font-family: Figtree;
    color: #fff;
    font-weight: 700;
}

.category-dropdown {
    position: absolute;
    width: 300px;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    background-color: #fff;
    z-index: 1000;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: max-height 0.3s ease-out;
    border-radius: 25px !important;
    border-color: rgba(255, 255, 255, 0.25) !important;
    border: 0.5px solid rgba(255, 255, 255, 0.25);
    padding: 15px;
    background-color: #000210 !important;
    scrollbar-color: #001DFF #000210;
}

.category-item+.category-item {
    margin-top: 10px;
}

.category-label {
    display: block;
    /* Makes the entire area clickable */
    align-items: center;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.95) !important;
    font-family: Figtree;
    font-size: 15px !important;
    font-style: normal !important;
    font-weight: 500 !important;
}

.category-checkbox {
    margin-right: 10px;
}

.sub-item-container {
    margin-left: 20px;
    padding-left: 10px;
    border-left: 2px solid #343a40;
    margin-top: 5px;
}

.sub-item-label {
    display: block;
    /* or 'flex' if you prefer items in a line */
    margin-top: 5px;
    color: rgba(255, 255, 255, 0.95) !important;
    font-family: Figtree;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
}

.sub-item-checkbox {
    margin-right: 10px;
}

.filter-no-result-found {
    color: rgba(255, 255, 255, 0.95) !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
}

.selected-category {
    background-color: #001DFF;
    color: #fff;
}

.selected-sub-item {
    background-color: #f0f0f0;
    color: #000;
}

.selected-category,
.selected-sub-item {
    /* Light grey background */
    font-family: Figtree;
    border-radius: 8px;
    /* Rounded corners */
    font-size: 13px;
    /* Font size */
    padding: 5px 10px;
    /* Padding inside the elements */
    margin: 5px;
    /* Space between elements */
    display: inline-flex;
    /* Display inline with flexbox for alignment */
    align-items: center;
    /* Align items vertically in the center */
    gap: 5px;
    /* Space between the text and the 'x' button */
}

.selected-sub-item {
    background-color: #d9edf7;
    /* Light blue background for sub-items */
}

.selected-token{
    background-color: #d9edf7;
    font-family: Figtree;
    border-radius: 8px;
    font-size: 13px;
    padding: 5px 10px;
    margin: 5px;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    color: #000;
}

.deselect-icon {
    cursor: pointer;
    /* Change cursor to pointer on hover over the 'x' button */
    display: inline-block;
    /* Ensure the 'x' is inline with text */
    padding: 0 5px;
    /* Padding around the 'x' for easier clicking */
}

/* Container for selected sub-items to ensure they display inline */
.selected-items-display {
    display: flex;
    /* Use flexbox */
    flex-wrap: wrap;
    /* Allow items to wrap to next line */
    margin-bottom: 10px;
    /* Bottom margin for space below the container */
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 10em;
    background: #000213;
    padding: 3px 10px;
    border-radius: 30px;
    border-color: rgba(255, 255, 255, 0.25);
    border: 0.5px solid rgba(255, 255, 255, 0.25);
    font-size: 14px;
    font-family: Figtree;
    /* width: fit-content; */
    margin: auto;
    color: #fff;
}

/* Adjust styles as necessary to match your design preferences */
.category-section-divider {
    border: 0.5px solid rgba(255, 255, 255, 0.25);
}


/* Filter.css */
.search-input-container {
    position: relative;
    display: inline-block;
    /* or 'block' depending on your layout */
    width: 100%;
}

.filter-search-input {
    padding-right: 30px;
    /* Make room for the clear button */
    /* Add more styling as needed */
}

.clear-search-button {
    position: absolute;
    right: 10px;
    top: 42%;
    transform: translateY(-50%);
    cursor: pointer;
    background-color: #ccc;
    /* Light grey background, adjust as needed */
    border-radius: 50%;
    /* Circular shape */
    width: 20px;
    /* Set width */
    height: 20px;
    /* Set height */
    display: flex;
    align-items: center;
    justify-content: center;
    color: white !important;
    /* Icon or text color */
}

/* Optional: Hover effect for the clear button */
.clear-search-button:hover {
    background-color: #b1b1b1;
    /* Darker shade on hover */
}

.select-deselect-buttons {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.select-deselect-buttons button {
    cursor: pointer;
    padding: 5px 15px;
    border-radius: 15px;
    font-size: 14px;
    font-family: Figtree;
    border: unset;
}

.select-deselect-buttons button:hover {
    background-color: #e2e2e2;
}


.selected-token-item,
.selected-keyword-item {
    display: inline-block;
    background-color: #d9edf7;
    border-radius: 8px;
    padding: 5px 10px;
    font-family: Figtree;
    margin: 5px;
    color: #000;
    font-size: 13px;
}

/* Add styles for hover effects on the deselect icon if desired */
.deselect-icon:hover {
    color: #666;
}

.selected-tokens-display {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 10em;
    background: #000213;
    padding: 3px 10px;
    border-radius: 30px;
    border-color: rgba(255, 255, 255, 0.25);
    border: 0.5px solid rgba(255, 255, 255, 0.25);
    font-size: 14px;
    font-family: Figtree;
    color: #fff;
    margin: auto;
    /* width: fit-content; */
}

.selected-keywords-display {
    font-size: 14px;
    font-family: Figtree;
    color: #fff;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 10em;
    background: #000213;
    padding: 3px 10px;
    border-radius: 30px;
    border-color: rgba(255, 255, 255, 0.25);
    border: 0.5px solid rgba(255, 255, 255, 0.25);
    margin: auto;
}

.select-all-btn {
    background-color: #001DFF !important;
    color: #fff;
    padding: 5px 5px;
}

@media (max-width: 768px) {
    .search-input-container {
        width: unset;
    }
    .selected-keywords-display,
    .selected-items-display,
    .selected-tokens-display {
        width: fit-content;
        margin: unset;
        margin-bottom: 10px;
    }
}

@media only screen and (min-width: 1281px) and (max-width: 1700px) {
    .search-input-container {
        width: 80%;       
    }

    .selected-keywords-display,
    .selected-items-display,
    .selected-tokens-display {
        width: 80%;
        margin: unset;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {

    .search-input-container{
        width: 65%;
    }
    .selected-keywords-display,
    .selected-items-display,
    .selected-tokens-display {
        width: 65%;
        margin: unset;
    }
}


.filter-dropdown-list{
    max-height: 200px;
    z-index: 100;
    padding: 15px;
    background-color: #000210 !important;
}
.token-item{
    padding: 20px;
}


.category-item-group{
    display: flex;
    align-items: center;
}

.filter-category-toggle-icon{
    color: rgba(255, 255, 255, 0.95) !important;
    margin-top: -5px;
    margin-right: 5px;
    cursor: pointer;
}