.switches-container {
    width: 10rem;
    position: relative;
    display: flex;
    padding: 0;
    position: relative;
    background: #000210;
    line-height: 2.4rem;
    border-radius: 3rem;

}

/* input (radio) for toggling. hidden - use labels for clicking on */
.switches-container input {
    visibility: hidden;
    position: absolute;
    top: 0;
}

/* labels for the input (radio) boxes - something to click on */
.switches-container label {
    width: 50%;
    padding: 0;
    margin: 0;
    text-align: center;
    cursor: pointer;
    opacity: 0.5;
    color: #FFF;
    text-align: center;
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    /* 17.585px */
}

/* switch highlighters wrapper (sliding left / right) 
    - need wrapper to enable the even margins around the highlight box
*/
.switch-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    padding: 0.15rem;
    z-index: 3;
    transition: transform .5s cubic-bezier(.77, 0, .175, 1);
    /* transition: transform 1s; */
}

/* switch box highlighter */
.switch {
    border-radius: 3rem;
    background: #001DFF;
    height: 100%;
}

/* switch box labels
    - default setup
    - toggle afterwards based on radio:checked status 
*/
.switch div {
    width: 100%;
    text-align: center;
    opacity: 0;
    display: block;
    transition: opacity .2s cubic-bezier(.77, 0, .175, 1) .125s;
    will-change: opacity;
    position: absolute;
    top: 0;
    left: 0;

    color: #FFF;
    text-align: center;
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    /* 17.585px */
}

/* slide the switch box from right to left */
.switches-container input:nth-of-type(1):checked~.switch-wrapper {
    transform: translateX(0%);
}

/* slide the switch box from left to right */
.switches-container input:nth-of-type(2):checked~.switch-wrapper {
    transform: translateX(100%);
}

/* toggle the switch box labels - first checkbox:checked - show first switch div */
.switches-container input:nth-of-type(1):checked~.switch-wrapper .switch div:nth-of-type(1) {
    opacity: 1;
}

/* toggle the switch box labels - second checkbox:checked - show second switch div */
.switches-container input:nth-of-type(2):checked~.switch-wrapper .switch div:nth-of-type(2) {
    opacity: 1;
}

.horizontal-line {
    border-top-color: rgba(255, 255, 255, 0.20);
}

.react-datepicker-wrapper input {
    border-radius: 25px;
    border: 0.5px solid rgba(255, 255, 255, 0.25);
    background: #000210;
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.61%;
    padding: 7px 15px;
    /* 17.585px */
}

.react-datepicker {
    border-radius: 25px !important;
    border: 1px solid rgba(255, 255, 255, 0.25) !important;
    background: #000210 !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: rgba(255, 255, 255, 0.95) !important;
    text-align: center !important;
    font-family: Figtree;
    font-size: 13.187px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    /* 13.187px */
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
    color: rgba(255, 255, 255, 0.95);

    text-align: center;
    font-family: Figtree;
    font-size: 13.187px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    /* 13.187px */
    opacity: 0.15;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: #001DFF !important;
}

.react-datepicker__header {
    background-color: #010318 !important;
    border-top-left-radius: 25px !important;
    border-top-right-radius: 25px !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    color: #fff !important;
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover{
    background-color: #001DFF !important;
}

.view-tweet-link {
    color: #aeaeae; /* Twitter blue */
    cursor: pointer;
    text-decoration: underline;
    margin-left: 5px;
}

.tweet-summary {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.tweet-summary input[type="checkbox"] {
    margin-right: 10px;
}

.tweet-summary label{
    color: rgba(255, 255, 255, 0.90) !important;
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 125.61%;
    font-weight: 500;
    padding: 10px 15px !important;
    vertical-align: middle;
}

.copy-modal-body-scrollable {
    max-height: 400px; /* Set the maximum height as needed */
    overflow-y: auto; /* Enable vertical scrolling */
    scrollbar-width: thin;
    /* "auto" or "thin" */
    scrollbar-color: #001DFF #000210;
}

/* customStyles.css */
.popover-custom {
    background-color: #000210 !important;
    color: #fff !important;
    border: 1px solid #001DFF !important;
    border-radius: 20px !important;
}

.popover-custom .popover-header {
    background-color: #007bff;
    color: #ffffff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.popover-custom .popover-body {
    color: #fff;
}

.popover-custom .popover-arrow::after {
    border-bottom-color: #001DFF !important;
}

.popover-header::before{
    content: unset !important;
}

.view-more-btn{
    font-size: 13px !important;
    color: #aeaeae !important;
}