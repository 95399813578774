.s-heading {
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 125.61%;
    /* 27.634px */
}

.s-sub-heading {
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 125.61%;
    opacity: 0.7;
    /* 16.329px */
}

.ticker-name {
    color: rgba(255, 255, 255, 0.95);
    leading-trim: both;
    text-edge: cap;
    font-family: Figtree;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 125.61%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    /* 22.61px */
}


.ticker-name:hover::after{
    content: attr(data-fulltext);
    position: absolute;
    left: 0;
    top: 100%;
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 3px;
    white-space: nowrap;
    z-index: 10;
    max-width: none;
    overflow: visible;
}

.ticker-symbol {
    color: rgba(255, 255, 255, 0.95);
    leading-trim: both;
    text-edge: cap;
    font-family: Figtree;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 125.61%;
    /* 15.073px */
    opacity: 0.7;
}

.token-address {
    color: rgba(255, 255, 255, 0.95);
    leading-trim: both;
    text-edge: cap;
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 125.61%;
    /* 15.073px */
}

.token-address-container {
    border-radius: 25px;
    background: #000210;
    width: fit-content;
    padding: 6px 10px;
    cursor: pointer;
}

.t-w-card {
    border-radius: 25px !important;
    background-color: rgba(255, 255, 255, 0.07) !important;
    padding: 10px;
    margin-right: 20px;
}

.t-tw {
    border-radius: 25px;
    background: rgba(199, 242, 167, 0.10);
    color: #C7F2A7;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.61%;
    /* 17.585px */
    padding: 8px 15px;
}

.tw-label {
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 125.61%;
    /* 16.329px */
    opacity: 0.7;
}

.tw-text {
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.61%;
    /* 20.098px */
}

.tw-social-icons {
    width: 22px;
    height: 22px;
}

.tw-social-icons-1 {
    width: 20px;
    height: 20px;
}

.ctw-btn {
    border-radius: 25px;
    border: 1px solid #001DFF;
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.61%;
    padding: 10px 15px;
    cursor: pointer;
    width: fit-content;
    /* 20.098px */
}

.slider-arrow-left,
.slider-arrow-right {
    border-radius: 30px;
    background-color: rgba(0, 29, 255, 0.35);
    border: unset;
    color: #001DFF;
    width: 40px;
    height: 40px;

}

.right-arrow-img {
    height: 11px;
}

.filter-label {
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.61%;
    opacity: 0.7;
}

.custom-dropdown {
    position: relative;
    width: 70%;
    border-radius: 25px;
    border: 0.5px solid rgba(255, 255, 255, 0.25);
    background: #000210;
}

.selected-option {
    cursor: pointer;
    padding: 10px;
    /* border: 1px solid #ccc; */
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.61%;
    opacity: 0.7;
    /* 17.585px */
}

.options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border-radius: 25px;
    border: 0.5px solid rgba(255, 255, 255, 0.25);
    background-color: #000210;
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.61%;
    z-index: 99;
    height: 200px;
    overflow: auto;
    /* 17.585px */
}

.option {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.option:hover,
.option.hovered {
    background-color: #000845;
    border-radius: 25px;
    /* Change this to your desired hover background color */
}

.watchlist-checkbox {
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.61%;
    opacity: 0.7;
    /* 20.098px */
}

.atw-table-container {
    border-radius: 25px;
    /* border-bottom: 0.5px solid rgba(255, 255, 255, 0.20); */
    background-color: #161521 !important;
    overflow: hidden;
    margin-bottom: 10px;
    table-layout: fixed;
    width: 100%;
}

.atw-table {
    margin-bottom: unset !important;
}

.atw-table>:not(caption)>*>* {
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.20);
    background-color: #161521 !important;
    color: rgba(255, 255, 255, 0.90) !important;
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 125.61%;
    font-weight: 500;
    padding: 30px 15px !important;
    vertical-align: middle;
    /* 20.098px */
}

.atw-table tr:last-child td,
.atw-table tr:last-child {
    border-bottom: unset;
}

.atw-table tr:nth-child(odd) td {
    /* border-bottom: 0.5px solid rgba(255, 255, 255, 0.20); */

    background-color: #121421 !important;
}

.atw-table tr:nth-child(even) td {
    /* border-bottom: 0.5px solid rgba(255, 255, 255, 0.20); */
    background-color: #0d0f1c !important;
    /* Set the background color for even rows */
}

.table-link-icon {
    width: 23px;
    cursor: pointer;
}

.table-heading-text {
    color: rgba(255, 255, 255, 0.60);
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.61%;
    /* 17.585px */
}

.atw-table th {
    color: rgba(255, 255, 255, 0.60);
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.61%;
    opacity: 0.7;
}

.time-window {

    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.61%;
    padding: 10px;
    opacity: 0.7;
    cursor: pointer;
    /* 20.098px */
}

.time-window.active {
    border-radius: 25px;
    background: rgba(0, 29, 255, 0.35);
    opacity: 1;
}

/** New Code **/
/* .avatar {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 2px solid #fff;
}


.avatar-stack .avatar-item {
    height: 40px;
    width: 40px;
    display: inline-block;
    transition: margin 0.1s ease-in-out;
}

.avatar-stack .avatar-item+.avatar-item {
    margin-left: -20px;
}

.avatar-stack .avatar-item:first-child {
    z-index: 5;
}

.avatar-stack .avatar-item:nth-child(2) {
    z-index: 4;
}

.avatar-stack .avatar-item:nth-child(3) {
    z-index: 3;
}

.avatar-stack .avatar-item:nth-child(4) {
    z-index: 2;
}

.avatar-stack .avatar-item:last-child {
    z-index: 1;
}

.avatar-stack:hover .avatar-item {
    margin-left: 0px;
    margin-right: 8px;
} */


.avatar-stack {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: -25px;
    position: relative;
}

.date-group {
    margin-right: 20px;
    /* Space between each date group */
    position: relative;
    cursor: pointer;
}

.date-group.reduced-margin {
    margin-right: 10px;
    /* Decreased margin */
}

.date-label {
    margin-bottom: 10px;
    /* Space between the date label and avatars */
    font-weight: bold;
}

.avatars {
    position: relative;
}

.avatar {
    width: 30px;
    /* Adjust the size as needed */
    height: 30px;
    /* Adjust the size as needed */
    border-radius: 25px;
    border: 0.5px solid rgba(255, 255, 255, 0.50);
    /* White border around avatars */
    position: absolute;
    object-fit: cover;
    transition: margin-top 0.3s ease;
    /* Apply transition to the bottom margin */
}

.avatar-stack::after {
    content: '';
    position: absolute;
    left: 40%;
    bottom: -10px;
    /* Adjust this value as needed */
    transform: translateX(-50%);
    width: 80%;
    /* Width of the line */
    height: 0.5px;
    /* Thickness of the line */
    background-color: rgba(199, 242, 167, 0.50);
    /* Color of the line */
}


/* Position the first three avatars */
.avatar:nth-of-type(1) {
    z-index: 3;
    top: 0;
}

.avatar:nth-of-type(2) {
    z-index: 2;
    top: 13px;
    /* Overlap amount */
}

.avatar:nth-of-type(3) {
    z-index: 1;
    top: 25px;
    /* Overlap amount */
}

/* Overlay for additional avatars */
.overlay {
    display: none;
    /* Hide overlay by default */
    align-items: center;
    justify-content: center;
    width: 30px;
    /* Same as avatar width */
    height: 30px;
    /* Same as avatar height */
    border-radius: 50%;
    background-color: #5064a9;
    /* Adjust color as needed */
    color: white;
    border: 2px solid white;
    position: absolute;
    top: 32px;
    /* Position at the bottom of the last visible avatar */
    left: 0;
    z-index: 12;
    /* Above all avatars */
    transition: margin-top 0.3s ease;
    font-size: 12px;
}

/* Reveal overlay and expand avatars on hover */
.date-group:hover .overlay {
    display: flex;
    /* Show overlay */
    opacity: 1;
    /* visibility: visible; */
    margin-top: 25px;
}

.date-group:hover .avatar:nth-of-type(n+2) {
    margin-top: 5px;
    /* Creates space between avatars */
    /* transition-delay: 100ms;
    transform: translateY(30px); */
}



/* Position additional avatars */
.date-group:hover .avatar:nth-of-type(n+4) {
    display: block;
    z-index: 0;
    /* Below the visible avatars */
    top: auto;
    /* Reset top positioning */
    bottom: -40px;
    /* Start from below the last visible avatar and move up */
    transition: bottom 0.3s ease;
    /* Transition for moving up */
}

.date-group:hover .avatar:nth-of-type(n+4):nth-last-of-type(1) {
    bottom: 20px;
    /* Overlap amount for the first hidden avatar */
}

.date-group:hover .avatar:nth-of-type(n+4):nth-last-of-type(2) {
    bottom: 40px;
    /* Overlap amount for the second hidden avatar */
}

.additional-tweet-ids {
    position: absolute;
    position: absolute;
    top: 65px;
    left: 0;
    max-width: 260px;
    background-color: #000210;
    border-radius: 5px;
    border: 3px solid #001DFF;
    /* border: 1px solid #ddd; */
    padding: 10px 20px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    z-index: 45;
    scrollbar-width: thin;
    /* For Firefox */
    scrollbar-color: #121421 #f0f0f0;
    /* For Firefox: thumb and track color */

    /* Webkit-based browsers: Chrome, Safari, etc. */
    &::-webkit-scrollbar {
        height: 8px;

        /* Height of the scrollbar */
    }

    &::-webkit-scrollbar-track {
        background: #000210;
        margin-bottom: 5px;
        /* Background of the scrollbar track */
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.20);
        /* Color of the scrollbar thumb */
        border-radius: 4px;

        /* Rounded corners of the scrollbar thumb */
        /* border: 2px solid; */
        /* Creates a border effect on the thumb */
    }
}

.additional-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid white;
    object-fit: cover;
    /* margin-left: -20px; */
}

.additional-avatar:first-child {
    margin-left: 0;
}

/* Pagination */

.pagination {
    display: flex;
    list-style-type: none;
    padding: 0;
}

.page-item {
    margin: 0 5px;
}

.page-item.dots {
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-link {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 35px;
    padding: 5px 10px;
    text-decoration: none;
    color: #fff !important;
    background-color: transparent !important;
    border: 1px solid rgba(255, 255, 255, 0.95) !important;
    opacity: 0.5;
    border-radius: 25px;
}

.page-item.active .page-link {
    border-radius: 25px;
    border: 1px solid #001DFF !important;
    background: #001DFF !important;
}

.page-link:hover {
    background-color: #333;
}

/* Styling for the Previous/Next buttons */
.page-item:first-child .page-link,
.page-item:last-child .page-link {
    border: none;
    background-color: transparent;
    color: #bbb;
    border-bottom-left-radius: 25px !important;
    border-top-left-radius: 25px !important;
    border-top-right-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
    /* border-radius: 25px; */
}

.page-item:first-child .page-link:hover,
.page-item:last-child .page-link:hover {
    color: #fff;
}

.popup {
    position: absolute;
    left: 0;
    top: 100%;
    padding: 10px;
    border-radius: 25px;
    border: 0.5px solid rgba(199, 242, 167, 0.50);
    background: #000;
    z-index: 9;
    display: flex;
}

.spinner-border {
    margin-left: auto;
    margin-right: auto;
    display: block !important;
}

.cursor-pointer {
    cursor: pointer;
}

.search-container {
    position: relative;
}

.dropdown-search-input {
    width: 100%;
    padding: 8px 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: unset;
    /* border-radius: 25px; */
    box-sizing: border-box;
    /* Makes sure padding doesn't affect the total width */
    background-color: #121421 !important;
    color: #fff;
}

/* Style for focus state */
.dropdown-search-input:focus {
    outline: none;
    border-color: #007bff;
    /* Change as per your theme */
}

/* Placeholder text style */
.dropdown-search-input::placeholder {
    color: #888;
}

.clear-button {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background: none;
    cursor: pointer;
    color: #6c757d;
}


/** filter css **/

.multi-select-filter {
    margin-top: 10px;
    width: 70%;
}

.multi-select-filter__control {
    border-radius: 25px !important;
    border-color: rgba(255, 255, 255, 0.25) !important;
    border: 0.5px solid rgba(255, 255, 255, 0.25);
    background-color: #000210 !important;
}

.multi-select-filter__menu {
    background-color: #000210 !important;
    color: #fff !important;
    z-index: 99 !important;
}

.multi-select-filter__placeholder {
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.multi-select-filter__option {
    color: rgba(255, 255, 255, 0.95) !important;
    font-family: Figtree;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
}


.multi-select-filter__input {
    color: #fff !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
}

.multi-select-filter__option--is-focused {
    background-color: #000845 !important;
}

/* 
.multi-select-filter__option:hover{
    background-color: #000845 !important;
} */



@media (max-width: 768px) {
    .time-window {
        padding: 8px;
        font-size: 14px;
    }

    .custom-dropdown {
        width: 100%;
    }
}

.ticker-img {
    width: 40px;
    border-radius: 25px;
}

.disabled-overlay {
    position: absolute;
    top: -25px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 29, 255, 0.20);
    /* Semi-transparent white */
    z-index: 101;
    /* Ensure it covers the content */
    display: flex;
    justify-content: center;
    /* align-items: center; */
    font-size: 20px;
    font-family: Figtree;
    color: #fff;
    padding: 15px 0px;
    border-radius: 25px;
    height: 500pc;
}

.custom-single-row-height {
    height: 200px;
}

.h-tweet-modal-h {
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 125.61%;
}

.investor-list {
    display: flex;
    flex-direction: column;
    max-height: 400px;
    /* Adjust the max height as needed */
    overflow-y: auto;
    scrollbar-width: thin;
    /* "auto" or "thin" */
    scrollbar-color: #001DFF #000210;
}


/* WebKit browsers (Chrome, Safari) */
.investor-list::-webkit-scrollbar {
    width: 8px; /* Adjust the width of the scrollbar */
  }
  
  .investor-list::-webkit-scrollbar-track {
    background: #f0f0f0; /* Background of the scrollbar track */
  }
  
  .investor-list::-webkit-scrollbar-thumb {
    background: #888; /* Background of the scrollbar thumb */
    border-radius: 4px; /* Rounded corners for the scrollbar thumb */
  }
  
  .investor-list::-webkit-scrollbar-thumb:hover {
    background: #555; /* Background of the scrollbar thumb on hover */
  }

.avatar-img-small {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;

}

.investor-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.investor-info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.investor-name {
    font-weight: bold;
    color: #fff;
}

.investor-type {
    color: #666;
}

.investor-tier {
    margin-right: 10px;
    font-weight: bold;
}

.investor-rating .star {
    color: #ccc;
    font-size: 18px;
}

.investor-rating .star.filled {
    color: #f1c40f;
}

.view-tweet-btn-modal {
    border-radius: 25px;
    border: 1px solid #001DFF;
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.61%;
    padding: 10px 15px;
    cursor: pointer;
    margin-left: 10px;
    background: unset;
}

.tweet-date {
    color: gray;
    font-size: 12px;
}

.back-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
  }

.modal-tweet-container{
    display: flex;
    justify-content: center;
}

.search-bar {
    width: 70%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #343a40;
    border-radius: 5px;
    font-size: 16px;
    background: #000210;
    color: #fff;
}

.search-sort-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }


.sort-btn {
    margin-bottom: 20px;
    padding: 10px;
    background-color: rgba(0, 29, 255, 0.20);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  

  
.stacked-avatars {
    display: flex;
    padding: 0;
    flex-direction: row;
}

.stacked-avatars .avatars__item {
    background-color: #596376;
    border: 2px solid #1f2532;
    border-radius: 100%;
    color: #ffffff;
    display: block;
    font-family: sans-serif;
    font-size: 12px;
    font-weight: 100;
    height: 45px;
    width: 45px;
    line-height: 45px;
    text-align: center;
    transition: margin 0.1s ease-in-out;
    overflow: hidden;
    margin-left: -10px;
}

.stacked-avatars .avatars__item .avatar-img {
    width: 100%;
    cursor: pointer;
}

.stacked-avatars:hover .stacked-avatars .avatars__item {
    margin-right: 10px;
}

.avatars__more {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    /* Same size as avatar */
    height: 40px;
    background-color: lightgray;
    /* Adjust as needed for badge background */
    color: black;
    /* Adjust as needed for badge text color */
    font-size: 14px;
    /* Adjust as needed for text size */
    font-weight: bold;
    cursor: pointer;
}

.contract-address-search {
    width: 100%;
    padding: 8px;
    margin-top: 8px;
    box-sizing: border-box;
    border-radius: 25px !important;
    border-color: rgba(255, 255, 255, 0.25);
    border: 0.5px solid rgba(255, 255, 255, 0.25);
    background-color: #000210 !important;
    font-family: Figtree;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
}

.tweet-complete-text{
    color: #fff;
}

.not-found-tweet-container{
    background-color:#15202b;
    border: 1px solid rgb(66, 83, 100);
    border-radius: 12px;
    padding: 15px;
    margin-bottom: 10px;
    margin: auto;
    width: fit-content;
}

.price{
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.61%;
}

.custom-0 {
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.61%;
  }
  
  .custom-subscript {
    font-size: 0.6em;
    vertical-align: sub;
  }

  .filter-group-home{
    background-color: #000210 !important;
    padding: 10px !important;
    border-radius: 25px !important;
  }

  .filter-notes{
    color:#aeaeae;
    font-size: 15px;
  }

  .reset-all-filter-btn{
    border-radius: 25px;
    border: 0.5px solid rgba(255, 255, 255, 0.25);
    color: #6c757d;
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.61%;
    padding: 10px 15px;
    cursor: pointer;
    width: -moz-fit-content;
    width: fit-content;
  }

  .influencer-home-tag{
    color: #666;
    font-size: 13px;
  }