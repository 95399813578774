.sidebar-container {
    display: 'flex';
    height: '100vh';
}

.sidebar {
    position: fixed !important;
    z-index: 99;
    top: 50px;
    left: 27px;
    height: 90%;
    border-right-style: unset !important;
    scrollbar-width: 1px;
    scrollbar-color: #001DFF #000210;
    /* width: 300px;
    min-width: 300px; */

}

.sidebar .ps-sidebar-container {
    background: linear-gradient(180deg, rgba(0, 5, 40, 0.00) 0%, rgba(2, 25, 202, 0.11) 100%), #000210;
    border-radius: 25px;
    border: 0.5px solid rgba(255, 255, 255, 0.20);

}

.sidebar .ps-sidebar-container li {
    color: #80818a;
    /* color: rgba(255, 255, 255, 0.95); */
    font-family: Figtree;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.61%;
    margin-bottom: 20px;
    /* 22.61px */
}

.sidebar-icons {
    padding-right: 10px;
}

.sidebar-icons-1 {
    margin-left: -7px;
}

.sidebar-label {
    margin-left: 10px;
    text-decoration: unset;
    color: inherit;
}

.sidebar-heading {
    color: rgba(255, 255, 255, 0.95);
    text-align: center;
    font-family: Fugaz One;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 125.61%;
    margin-bottom: 20px;
    margin-top: 20px;
    /* 30.146px */
}

.sidebar .ps-sidebar-container li.ps-active {
    border-radius: 0px 40px 40px 0px;
    border: 1.5px solid #001DFF;
    background: rgba(0, 29, 255, 0.20);
    color: rgba(255, 255, 255, 0.95);
    font-weight: 600;
    border-left: 8px solid #001DFF;
}

.sidebar .ps-sidebar-container li a:hover {
    background: rgba(0, 29, 255, 0.20);
    border-radius: 0px 40px 40px 0px;
}

.sidebar .ps-sidebar-container nav {
    padding-right: 15px;
    margin-top: 40px;
}

.collapse-menu {
    position: fixed;
    top: 20px;
    z-index: 99;
    cursor: pointer;
}

.collapse-menu.menu-opned {
    left: 340px;
}

.collapse-menu.menu-closed {
    left: 115px;
}

.mobile-wallet {
    display: none;
}

@media (max-width: 768px) {
    .collapse-menu.menu-closed {
        left: 15px;
        top: 15px;
    }

    .sidebar-container {
        height: unset;
    }

    .credit-header {
        width: fit-content;
        margin: auto;
    }

    w3m-button {
        margin-bottom: 10px;
        margin-left: 14%;
        margin: auto auto 10px auto;
    }

    .mobile-wallet {
        display: block;
    }

    .sidebar {
        left: 0px;
    }

    .collapse-menu.menu-opned {
        left: 15    px;
    }

    .search-bar-container {
        width: 80%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1025px) {
    .search-bar-container {
        width: 30%;
    }

    .collapse-menu.menu-opned {
        left: 40px;
    }

    .collapse-menu.menu-closed {
        left: 40px;
    }

    .search-bar-container {
        width: 80%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .sidebar {
        width: 180px !important;
        min-width: 180px !important;
    }

    .sidebar.ps-collapsed {
        width: 80px !important;
        min-width: 80px !important;
    }

    .page-container {
        padding-left: 50px !important;
        padding-top: 40px !important;
    }

    .sidebar .ps-sidebar-container li {
        font-size: 15px;
    }
}

/* CSS for small laptops/screens */
@media only screen and (min-width: 1025px) and (max-width: 1280px) {

    /* .sidebar{
        width: 180px !important;
        min-width: 180px !important;
    }
    .sidebar.ps-collapsed {
        width: unset;
        min-width: unset;
    } */
    .collapse-menu.menu-closed {
        left: 40px;
    }

    .collapse-menu.menu-opned {
        left: 40px;
    }

    .search-bar-container {
        width: 35%;
        left: 8%;
    }
}

@media only screen and (min-width: 1280px) and (max-width: 1400px) {
    .search-bar-container {
        width: 35%;
    }

    .collapse-menu.menu-closed {
        left: 40px;
    }

    .collapse-menu.menu-opned {
        left: 40px;
    }
}

@media (min-width: 992px) and (max-width: 1280px) {
    .sidebar {
        width: 180px !important;
        min-width: 180px !important;
    }

    .sidebar.ps-collapsed {
        width: 80px !important;
        min-width: 80px !important;
    }

    .page-container {
        padding-left: 40px !important;
    }

    .sidebar .ps-sidebar-container li {
        font-size: 15px;
    }
}

/* CSS for medium screens */
@media only screen and (min-width: 1281px) and (max-width: 1700px) {
    .collapse-menu.menu-opned {
        left: 40px;
    }

    .collapse-menu.menu-closed {
        left: 40px;
    }

    .sidebar {
        width: 220px !important;
        min-width: 22   0px !important;
    }

    .page-container {
        padding-left: 100px !important;
    }

    .sidebar.ps-collapsed {
        width: 80px !important;
        min-width: 80px !important;
    }

    .sidebar .ps-sidebar-container li {
        font-size: 15px;
    }
}