.influencer-name {
    color: rgba(255, 255, 255, 0.95);
    leading-trim: both;
    text-edge: cap;
    font-family: Figtree;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 125.61%;
    /* 27.634px */
}

.influencer-handle {
    color: rgba(255, 255, 255, 0.95);
    leading-trim: both;
    text-edge: cap;
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 125.61%;
    /* 20.098px */
    opacity: 0.7;
}

.followers-count {
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 125.61%;
    /* 22.61px */
}

.followers-text {
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 125.61%;
    /* 20.098px */
    opacity: 0.7;
}

.influ-pro-img{
    border-radius: 30px;
}

.influencer-tweet-feed .react-tweet-theme{
    margin: 10px auto;
}

/* CSS to make the filter card fixed */
.fixed-filter {
    position: fixed !important;
    top: 85px;
    width: 40%;
  }
  