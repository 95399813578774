.navbar {
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.20) !important;
  background: #010318 !important;
  z-index: 99 !important;
}

.navbar-text {
  margin-right: 10px;
}

.bell-icon-container {
  position: relative;
  display: inline-block;
}

.bell-icon {
  margin-right: 10px;
  /* Adjust the margin as needed */
  font-size: 30px;
}

.notification-dot {
  position: absolute;
  top: 5px;
  right: 10px;
  width: 12px;
  height: 12px;
  background-color: #ff484b;
  border-radius: 50%;
}

.credit-header {
  color: rgba(255, 255, 255, 0.95);
  font-family: Figtree;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  border: 0.5px solid rgba(199, 242, 167, 0.2);
  border-radius: 24px;
  padding: 8px 10px;
}

@media (max-width: 768px) {
  .header-menu-item {
    display: none !important;
  }

  .navbar {
    border-bottom: unset !important;
    background: unset !important;
    z-index: unset !important;
  }
}



.search-bar-container {
  width: 50%;
  position: relative;
  left: 8%;
}

.search-bar-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}


.search-bar-input-wrapper input {
  width: 100%;
  padding: 7px 10px;
  margin-bottom: 5px;
  box-sizing: border-box;
  border-radius: 25px !important;
  border-color: rgba(255, 255, 255, 0.25);
  border: 0.5px solid rgba(255, 255, 255, 0.25);
  background-color: #000210 !important;
  font-family: Figtree;
  font-size: 14px;
  font-weight: 400;
  /* line-height: 18px; */
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
}

.clear-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  color: #aaa;
}

.clear-icon:hover {
  color: #001DFF;
}

.search-results-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: max-height 0.3s ease-out;
  border-radius: 25px !important;
  border-color: rgba(255, 255, 255, 0.25) !important;
  border: 0.5px solid rgba(255, 255, 255, 0.25);
  padding: 15px;
  background-color: #000210 !important;
  color: #fff;
  font-size: 14px;
  font-family: Figtree;
  scrollbar-color: #001DFF #000210;
}

.search-results-heading {
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #001DFF;
  color: rgba(255, 255, 255, 0.90);
}

.search-result-item {
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

.search-token {
  font-size: 16px;
  font-family: Figtree;
}

.search-influencer {
  font-size: 16px;
  font-family: Figtree;
}

.search-result-item:hover {
  background-color: rgba(0, 29, 255, 0.20);
}

.loading,
.not-found {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: max-height 0.3s ease-out;
  border-radius: 25px !important;
  border-color: rgba(255, 255, 255, 0.25) !important;
  border: 0.5px solid rgba(255, 255, 255, 0.25);
  padding: 15px;
  background-color: #000210 !important;
  color: #fff;
  font-size: 14px;
  font-family: Figtree;
}

.search-token-address {
  color: #aeaeae;
  font-size: 13px;
}