.deposit-input {
    color: rgba(255, 255, 255, 0.95);
    font-family: Figtree;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 125.61%;
    border-radius: 25px !important;
    border: 0.5px solid rgba(255, 255, 255, 0.25);
    background-color: #000210 !important;
    width: 90%;
    padding: 10px;
    /* 17.585px */
}

.profile-text {
    font-family: Figtree;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    /* text-align: left; */
    color: rgba(255, 255, 255, 0.95);
}

.pre-amt {
    border: 0.5px solid rgba(255, 255, 255, 0.25);
    font-family: Figtree;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
    border-radius: 25px;
    padding: 5px 10px;
    margin-right: 20px;
    cursor: pointer;
}

.credit-text {
    font-family: Figtree;
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
}

@media (max-width: 768px) {
    .pre-amt {
        margin-right: 5px;
    }
}

.remove-telegram{
    color: rgba(255, 72, 75, 0.8);
    font-family: Figtree;
    font-size: 15px;
    cursor: pointer;
}