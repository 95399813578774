.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.page-container {
  margin-top: 100px !important;
}

.notification-permission-modal .modal-header {
  border-bottom: .5px solid #fff3 !important
}

.notification-permission-modal .modal-content {
  background-color: #010318 !important;
}

.notification-permission-modal .modal-title {
  color: #fff;
  font-family: Figtree;
  font-style: normal;
  font-weight: 400;
}

.notification-permission-modal .modal-body {
  color: #fff;
  font-family: Figtree;
  font-style: normal;
  font-weight: 400;
}

.notification-permission-modal .modal-footer {
  border-top: .5px solid #fff3 !important
}

.notification-permission-modal .enable-notification-btn {
  background-color: #001dff;
  border: unset;
}

@media (max-width: 768px) {
  .page-container {
    margin-left: 70px;
    width: 85% !important;
    margin-top:50px !important;
  }
}